import { MapContainer, Marker, Popup, TileLayer,ZoomControl, LayersControl,LayerGroup,useMap } from 'react-leaflet'
import axios from '../axios';
import React, { useState,useRef,useContext,useEffect } from 'react'
//import { geosearch } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import Wkt from 'wicket';
import {  myIconCPT, myIconCPTU,myIconCPTE, myIconHVSR, myIconMASW, myIconDH, icon  } from '../custom_icon';
import LeafletRuler from "../LeafletRuler";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import {DataContext} from '../context/DataContext'
//import style from './MapComponent.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import "leaflet-geosearch/dist/geosearch.css";
import { useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

    function LeafletgeoSearch() {
      const map = useMap(); //here use useMap hook
      useEffect(() => {
        const provider = new OpenStreetMapProvider();
        const searchControl = new GeoSearchControl({
          provider,
          marker: {
            icon
          }
        });
        map.addControl(searchControl);
        return () => map.removeControl(searchControl)
      }, []);
      return null;
    }

export const MapComponent_Page2 = () => {

    const {setprof,setparQC,setparfs,setparMV,setparMH,setparVS} = useContext(DataContext)
    const location = useLocation();

    var positionDefault=location.state.coord;

    useEffect(() => {
        var parQC=location.state.qc;
        var prof=location.state.h;
        var parfs=location.state.fs;
        setparQC(parQC)
        setparfs(parfs)
        setprof(prof)

        var parMV=location.state.mv;
        var parMH=location.state.mh;
        var parVS=location.state.vs;
        setparMV(parMV)
        setparMH(parMH)
        setparVS(parVS)

        },[])

    function viewDataCPT(e){
      let id=e.target.attributes[0].nodeValue
      axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
      const urlData= 'https://www.shapp-api.sofhare.com/outcpt/'+id+'/proves/'
      const requestData=axios.get(urlData)
      Promise.all([requestData]).then(res => {
       const responseData = res[0].data[0]
       var veryjson=responseData.output_data.replace(/'/g, '"')
       var datjson=JSON.parse(veryjson)
       var parQC=datjson[0].QC;
       var prof=datjson[0].H;
       var parfs=datjson[0].FS;
        setparQC(parQC)
        setparfs(parfs)
        setprof(prof)
      }).catch(errors => {
             console.log(errors)
             })
    }

    function viewDataSIS(e){
	    let id=e.target.attributes[0].nodeValue
        axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
        const urliddata='https://www.shapp-api.sofhare.com/outsis/'+id+'/proves/'
        const data= axios.get(urliddata)
        Promise.all([data]).then(res => {
        const values=res[0].data[0]
        var veryjson=values.data.replace(/'/g, '"')
        var datjson=JSON.parse(veryjson)
        var parMV=datjson['value'][0]['ModelV'];
        var parMH=datjson['value'][0]['ModelH'];
        var parVS=values.data.VS;
        setparMV(parMV)
        setparMH(parMH)
        setparVS(parVS)
      }).catch(errors => {
             console.log(errors)
             })
    }

    function downloadJsonInputCPT(e){
      let id=e.target.attributes[0].nodeValue
      console.log(id)
      axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
      axios.get(`https://www.shapp-api.sofhare.com/inputcpt/proves/`)
      .then(res => {
        var json = res.data.find(item => item.id == id);
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
        var dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem.setAttribute("href",     dataStr     );
        dlAnchorElem.setAttribute("download", "input-"+id+"__RX.json");
        dlAnchorElem.click();
      }).catch(errors => {
             console.log(errors)
             })
    }

    function downloadJsonOutSis(e){
        let id=e.target.attributes[0].nodeValue
        axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
        const urliddata='https://www.shapp-api.sofhare.com/outsis/'+id+'/proves/'
        const data= axios.get(urliddata)
        Promise.all([data]).then(res => {
        const values=res[0].data[0]
        var veryjson=values.data.replace(/'/g, '"')
        var datjson=JSON.parse(veryjson)
        var parMV=datjson['value'][0]['ModelV'];
        var parMH=datjson['value'][0]['ModelH'];
        var parVS=values.vsh;
        toast.info('Vs,eq '+ parVS+' m/s', {position: toast.POSITION.TOP_RIGHT,autoClose: 8000});
        // serve per creare il grafico dai dati
        //const my_data = []
        //var tetto=0
        //var letto=parMH[0]
        //var Vel= parMV[0]
        //let ele_t=[Vel,tetto]
        //let ele_l=[Vel,letto]
        //my_data.push(ele_t)
        //my_data.push(ele_l)
        //for (let i = 1; i < parMV.length; i++) {
          //   tetto +=parMH[i-1]
          //   letto +=parMH[i]
          //  Vel= parMV[i]
          //   let ele_t=[Vel,tetto]
          //   let ele_l=[Vel,letto]
          //   my_data.push(ele_t)
          //   my_data.push(ele_l)
           // }
      }).catch(errors => {
             console.log(errors)
             })
    }


  const [geopoints, setGeopoints] = useState([]);
  const [check_geog, setCheck] = useState(true);
  const [check_cpt, setCheck_cpt] = useState(true);
  const [check_cptu, setCheck_cptu] = useState(true);
  const [check_cpte, setCheck_cpte] = useState(true);

  const [sispoints, setSispoints] = useState([]);
  const [check_sis, setChecksis] = useState(true);
  const [check_hvsr, setCheck_hvsr] = useState(true);
  const [check_masw, setCheck_masw] = useState(true);
  const [check_dh, setCheck_dh] = useState(true);

  const [pagesis, setpagesis] = useState(0);
  const [pagegeo, setpagegeo] = useState(0);


  const mapRef = useRef();
  const addLayers = (e) => {
    if(e.target.name=='prove_geog'){
        setCheck_cpt(e.target.checked);
        setCheck_cptu(e.target.checked);
        setCheck_cpte(e.target.checked);
        setCheck(e.target.checked);
    }
    if(e.target.name=='prove_cpt'){
        setCheck_cpt(e.target.checked);
    }
    if(e.target.name=='prove_cptu'){
        setCheck_cptu(e.target.checked);
    }
    if(e.target.name=='prove_cpte'){
        setCheck_cpte(e.target.checked);
    }
    if(e.target.name=='prove_sis'){
        setCheck_hvsr(e.target.checked);
        setCheck_masw(e.target.checked);
        setCheck_dh(e.target.checked);
        setChecksis(e.target.checked);
    }
    if(e.target.name=='prove_hvsr'){
        setCheck_hvsr(e.target.checked);
    }
    if(e.target.name=='prove_masw'){
        setCheck_masw(e.target.checked);
    }
    if(e.target.name=='prove_dh'){
        setCheck_dh(e.target.checked);
    }


  };

  useEffect(() => {
        renderGEO();
        countDATA();
     },[pagegeo]);


     useEffect(() => {
        renderSIS();
        countDATA();
     },[pagesis]);


     function countDATA(){
        axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
        Promise.all([axios.get(`https://www.shapp-api.sofhare.com/counter/proves/`)])
        .then(res => {
        const page =Math.ceil((res[0].data.find(item => item.type == 'TOTSIS').Tot_geog)/10);
        const pageg =Math.ceil((res[0].data.find(item => item.type == 'TOTCPT').Tot_geog)/10);
        setpagesis(page);
        setpagegeo(pageg);
        })
     }

     function renderGEO() {
        for (let pgg = 1; pgg < pagegeo+1; pgg++) {
                axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
                const geogndata=['https://www.shapp-api.sofhare.com/datacpt/proves/?page='+pgg,
                    ]
                const getgeo=geogndata.map((endpoint) => axios.get(endpoint))
                let promises = [];
                promises.push(axios.get(geogndata))
                Promise.all(promises)
                .then(res => {
                    const newdata = res[0].data.results
                    .map(item => {
                    const wkt_geom = item.geom.replace('SRID=4326;','')
                    const wkt = new Wkt.Wkt();
                    wkt.read(wkt_geom)
                    const geojson_geom = wkt.toJson()
                    const coords2 = geojson_geom['coordinates']
                    return setGeopoints(geopoints=> [...geopoints, {coords: coords2, id: item.id, tipo:item.tipo_prova}]);
                    });
                }).catch(errors => {
                console.log(errors);
                         })
        }
      }

    function renderSIS() {
        for (let pg = 1; pg < pagesis+1; pg++) {
                axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
                const sisdata=['https://www.shapp-api.sofhare.com/datasis/proves/?page='+pg,
                    ]
                const getsis=sisdata.map((endpoint) => axios.get(endpoint))
                let promises = [];
                promises.push(axios.get(sisdata))
                Promise.all(promises)
                .then(res => {
                    const newdatasis = res[0].data.results.map(item => {
                    const wkt_geom = item.geom.replace('SRID=4326;','')
                    const wkt = new Wkt.Wkt();
                    wkt.read(wkt_geom)
                    const geojson_geom = wkt.toJson()
                    const coords2 = geojson_geom['coordinates']
                    return  setSispoints(sispoints=> [...sispoints, {coords: coords2, id: item.id, tipo:item.tipo_prova}]);
                    });
                }).catch(errors => {
                console.log(errors);
                         })
        }
    }


 return (
        <div className="general_pg2">
            <div className='map1_pg2'>
            <a href="/#" id="downloadAnchorElem" style={{ display:"none" }} >a</a>
                <MapContainer  center={positionDefault} zoom={22} zoomControl={false} inneRef={mapRef} style={{ height: '100%', width: '100%',borderRadius: '7px'}}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                            <TileLayer
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer unchecked name="Satellite ESRI">
                            <TileLayer
                                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                attribution='Map Tiles © 2022 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS,Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
                            />
                        </LayersControl.BaseLayer>
                       <div >
                            <LayerGroup  >
                              <MarkerClusterGroup showCoverageOnHover={false} >
                               {
                                geopoints.filter((point,i) =>
                                    (check_cpt==true&&point.tipo=='CPT')||(check_cptu==true&&point.tipo=='CPTU')||(check_cpte==true&&point.tipo=='CPTE')
                                ).map(function(point, i){
                                    var myIcon;
                                    if(point.tipo=='CPT'){
                                        myIcon=myIconCPT;
                                    }
                                    else if(point.tipo=='CPTE'){
                                        myIcon=myIconCPTE;
                                        }
                                    else{
                                        myIcon=myIconCPTU;
                                    }
                                    return (<Marker key={i} position={[point.coords[1], point.coords[0]]} icon={myIcon}>
                                       <Popup>
                                           ID : {point.id}  <br></br>
                                           Tipologia: {point.tipo}
                                           <br>
                                           </br>
                                           <button  onClick={viewDataCPT} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 25px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '2px'}}>Visualizza dati</button>
                                           <br>
                                           </br>
                                           <button  onClick={downloadJsonInputCPT} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 13px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Download input data</button>

                                       </Popup>
                                     </Marker>);
                                    }
                                )};
                              </MarkerClusterGroup>

                              <MarkerClusterGroup showCoverageOnHover={false} >
                               {
                                sispoints.filter((point,i) =>
                                    (check_hvsr==true&&point.tipo=='HVSR')||(check_masw==true&&point.tipo=='MASW')||(check_dh==true&&point.tipo=='DH')
                                ).map(function(point, i){
                                    var myIcon;
                                    if(point.tipo=='HVSR'){
                                        myIcon=myIconHVSR;
                                    }
                                    else if(point.tipo=='MASW'){
                                        myIcon=myIconMASW;
                                        }
                                    else{
                                        myIcon=myIconDH;
                                    }

                                    return (<Marker key={i} position={[point.coords[1], point.coords[0]]} icon={myIcon}>
                                       <Popup>
                                           ID : {point.id}  <br></br>
                                           Tipologia: {point.tipo}
                                           <br>
                                           </br>
                                           Vs,eq: {point.tipo}
                                           <br>
                                           </br>
                                           <button onClick={viewDataSIS} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 16px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Visualizza dati</button>
                                           <br>
                                           </br>
                                           <button  onClick={downloadJsonOutSis} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 13px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Visualizza Vs,eq</button>

                                       </Popup>
                                     </Marker>);
                                    }
                                )};
                              </MarkerClusterGroup>

                            </LayerGroup>
                      </div>
                      </LayersControl>
                    <ZoomControl position='topleft'/>
                    <LeafletRuler />
                   <LeafletgeoSearch />
                </MapContainer>

            </div>
            <div className="layertab_pg2">
                  <table>
                      <tbody>
                           <tr>
                              <th>Layer</th>
                            </tr>

                            <tr>
                              <td><input type="checkbox" checked={check_geog} onChange={addLayers}  name="prove_geog" /></td>
                              <td></td>
                              <td>Prove geognostiche</td>

                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cpt} onChange={addLayers}  name="prove_cpt" /></td>
                              <td>Prove CPT</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cpt.png" height="35" alt="image_cpt"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cptu} onChange={addLayers}  name="prove_cptu" /></td>
                              <td>Prove CPTU</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cptu.png" height="35" alt="image_cptu"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cpte} onChange={addLayers}  name="prove_cpte" /></td>
                              <td>Prove CPTE</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cpte.png" height="35" alt="image_cpte"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td><input type="checkbox" checked={check_sis} onChange={addLayers}  name="prove_sis" /></td>
                              <td></td>
                              <td>Prove geofisiche</td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_hvsr} onChange={addLayers}  name="prove_hvsr" /></td>
                              <td>Prove HVSR</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_hvsr.png" height="35" alt="image_hvsr"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_masw} onChange={addLayers}  name="prove_masw" /></td>
                              <td>Prove MASW</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_masw.png" height="35" alt="image_masw"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_dh} onChange={addLayers}  name="prove_dh" /></td>
                              <td>Prove DH</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_dh.png" height="35" alt="image_dh"/>
                                  </a>
                              </td>
                            </tr>

                      </tbody>
                  </table>
            </div>
        </div>

  )

}
