import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import Navbar from './navbar';
import Footer from './footer-bs';
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
<>

    <Navbar />
    <App />
    <Footer />
    <CookieConsent location="top">This website uses cookies to enhance the user experience.</CookieConsent>
    <ToastContainer />
</>,

 document.getElementById('root'));

//serviceWorker.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

