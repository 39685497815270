import './ticket.css';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';




function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_u317szf', 'template_gyjtsht', e.target, 'Xl4cHzs5_rTnbe391')
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
      }, (error) => {
          console.log(error.text);
      });
  }


function Ticket() {

        const navigate = useNavigate();

        return(

        <div>
            <div className="Principal_tk">
                <div className="Title">
                    <h1> Apri un Ticket </h1>
                </div>
                <div className="layertab_newdata_ticket">

                <div className="form_data">

                   <form className="contact-form" onSubmit={sendEmail}>
                      <label>e-mail</label>
                      <br />
                      <input type="text" id='email' name="email" />
                      <br />

                      <br />
                      <label>Note</label>
                      <br />
                      <textarea name="note" />
                      <br />

                      <input type="submit" value="Invia richiesta" />
                   </form>
                  <br />
            </div>
            </div>
            </div>

            <div className="bt_return">
                    <button onClick={() => navigate('/sHapp')} type="button" style={{'fontFamily':'roboto','fontSize': '24px',border:'3px solid #12455b',color: 'black',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}> <i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                </div>


        </div>

    );
}


export default Ticket;
