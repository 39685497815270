import './Contatti.css';

function Contatti() {

    return(

        <div>

            <div className="im-ct">
                    <div className="text-ct">

                       <i class="fa fa-phone fa-4x" aria-hidden="true"> +39 3496050251</i>
                       <br/>
                       <br/>
                       <br/>
                       <i class="fa fa-envelope fa-3x" aria-hidden="true"> umberto.grechi@sofhare.com</i>
                       <br/>
                       <br/>
                       <br/>
                       <br/>
                       <i class="fa fa-address-book fa-3x" aria-hidden="true"> Via Nazionale 54/8, 40065 Pianoro (BO)</i>
                       <br/>

                    </div>
                    <br/>
             </div>

            <div className="home-title">
                    <h1> Contatti</h1>
             </div>
             <br/>

            <br/>

        </div>


    );
}


export default Contatti;
