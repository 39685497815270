import './navbar.css';
import 'react-toastify/dist/ReactToastify.css';
import {handleLogout} from './Login';


function Navbar()
{

  return (
    <div className="navbar">
        <div className="dropdown">
            <a href="\">Home</a>
        </div>

        <div className="dropdown">
            <a href="\Contatti">Contatti</a>
        </div>

        <div className="dropdown">
            <button className="dropbtn">Servizi
                <i className="fa fa-caret-down"></i>
            </button>
                <div className="dropdown-content">
                    <a href="\Geoutilities">Geo Utilities</a>
                    <a href="\WebGIS">WebGIS</a>
                    <a href="\sHapp">sHApp</a>

                </div>
        </div>

        <div className="dropdown">
            <button className="dropbtn">Area riservata
                <i className="fa fa-caret-down"></i>
            </button>
                <div className="dropdown-content">
                    <a href="\Login">Login</a>
                    <a onClick={handleLogout}>Logout</a>
                </div>
        </div>

    </div>
  );
}


export default Navbar;