import React, { Component } from "react";
import './App.css';
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import Shapp_p1 from './shapp_p1';
import Shapp_p2 from './shapp_p2';
import Shapp_p3 from './shapp_p3';
import Develope  from './Develope';
import Login  from './Login';
import Ticket  from './Ticket';

import Home  from './Home';
import Contatti  from './Contatti';
import Geoutilities  from './Geoutilities';
import Webgis from './Webgis';

import jwt_decode from "jwt-decode";

    class App extends Component {
      
      state = {
        isAuthenticated: true,
      };


      componentDidMount() {
        if (localStorage.getItem('token') !== null) {

          console.log(localStorage.getItem('token'))



          const token = localStorage.getItem('token');
          const refreshToken = localStorage.getItem('refresh_token')

          var decodedToken=jwt_decode(token);
          var decodedToken2=jwt_decode(refreshToken);

          var dateNow = new Date();
          if(decodedToken.exp < dateNow.getTime()/1000){

          this.setState({ isAuthenticated: false });
          console.log('non autenticato')}

        } else {
          this.setState({ isAuthenticated: false });
          console.log('non autenticato')
        }
      }

      render() {
        console.log('deve deviare su page1')
        //se sono autenticato
        let routes = (

        <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Contatti" element={<Contatti />} />
                <Route path="/Geoutilities" element={<Geoutilities />} />
                <Route path="/Webgis" element={<Webgis />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/sHapp" element={<Shapp_p1 />} />
                <Route path="/sHapp_lstdata" element={<Shapp_p2 />} />
                <Route path="/sHapp_insdata" element={<Shapp_p3 />} />
                <Route path="/Ticket" element={<Ticket />} />
            </Routes>
            )

       //se non sono autenticato
        if (!this.state.isAuthenticated) {
        console.log('solo login')
           routes = (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Contatti" element={<Contatti />} />
                <Route path="/Geoutilities" element={<Geoutilities />} />
                <Route path="/Webgis" element={<Webgis />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/sHapp" element={<Login />} />
                <Route path="/sHapp_lstdata" element={<Login />} />
                 <Route path="/sHapp_insdata" element={<Login />} />
            </Routes>

          );
        }

        return <Router>{routes}</Router>;
      }}


export default App