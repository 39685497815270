import L from 'leaflet';
import marker_cpt  from './static/Icons/icon_cpt.png'
import marker_cptu from './static/Icons/icon_cptu.png'
import marker_cpte from './static/Icons/icon_cpte.png'
import marker_hvsr from './static/Icons/icon_hvsr.png'
import marker_masw from './static/Icons/icon_masw.png'
import marker_dh from './static/Icons/icon_dh.png'
import marker_sc from './static/Icons/center.png'
import marker_new_data from './static/Icons/new_data.png'


const icon_new_data = new L.Icon({
    iconUrl: marker_new_data,
    iconRetinaUrl: marker_new_data,
    popupAnchor:  [-0, -0],
    iconSize: [30,30],
});


const myIconCPT = new L.Icon({
    iconUrl: marker_cpt,
    iconRetinaUrl: marker_cpt,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const myIconCPTU = new L.Icon({
    iconUrl: marker_cptu,
    iconRetinaUrl: marker_cptu,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const myIconCPTE = new L.Icon({
    iconUrl: marker_cpte,
    iconRetinaUrl: marker_cptu,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const myIconHVSR = new L.Icon({
    iconUrl: marker_hvsr,
    iconRetinaUrl: marker_hvsr,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const myIconMASW = new L.Icon({
    iconUrl: marker_masw,
    iconRetinaUrl: marker_masw,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const myIconDH= new L.Icon({
    iconUrl: marker_dh,
    iconRetinaUrl: marker_dh,
    popupAnchor:  [-0, -0],
    iconSize: [40,50],
});

const icon = new L.Icon({
  iconSize: [80, 80],
  popupAnchor: [2, -40],
  iconUrl: marker_sc ,
});




export { myIconCPT, myIconCPTU,  myIconCPTE, myIconHVSR, myIconMASW, myIconDH, icon ,icon_new_data};