import 'leaflet/dist/leaflet.css';
import './footer-bs.css';
import { MapComponent_Page3 } from './components/MapComponent_Page3';
import { useNavigate } from 'react-router-dom';



function Shapp_p3() {

    const navigate = useNavigate();

    return(

       <div>
       <div className="Box_3">
            <div className="Title">
                  <h1> sHApp </h1>
            </div>

             <div className="App">
                  <MapComponent_Page3 />
            </div>

            <div className="bt_return_2">
                  <button onClick={() => navigate('/sHapp')} type="button" style={{'fontFamily':'roboto','fontSize': '24px',border:'3px solid #12455b',color: 'black',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}> <i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            </div>
        </div>
        </div>

    );
}


export default Shapp_p3;




