import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from '../axios';

const dt = () => {
  return new Promise((resolve, reject) => {

    axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
    axios
      .get(`https://www.shapp-api.sofhare.com/counter/proves/`)
      .then((res) => {
        const filtelongeur  = res.data.find(item => item.type == 'TOTCPT');
        const longeur  = filtelongeur.Tot_geog;
        const filtercpt  = res.data.find(item => item.type == 'CPT');
        const lencpt = filtercpt.Tot_geog;
        const filtercptu = res.data.find(item => item.type == 'CPTU');
        const lencptu = filtercptu.Tot_geog;
        const filtercpte = res.data.find(item => item.type == 'CPTE');
        const lencpte = filtercpte.Tot_geog;
        resolve({
          lencpt,
          lencptu,
          lencpte,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const Graph = () => {

    const [series, setSeries] = useState([0, 0, 0]);

      const [options] = useState({
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '18px',
        fontFamily: 'roboto',
        fontWeight: 800,
        colors: ['#CC929292'],
      },
      background: {
        enabled: true,
        foreColor: 'black',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#E9A628',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
    },

    plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                value: {
                  show: true,
                  fontSize: '40px',
                  fontFamily: 'roboto',

                  color: '#E9A628',
                  offsetY: 20,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Totale',
                  color: '#E9A628',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: true,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: true,
      theme: false,
      style: {
        fontSize: '14px',
        fontFamily: 'roboto',
      },
    },
    labels: ['CPT', 'CPTU', 'CPTE'],

    colors: ['#e50021', '#005efa', '#fab000'],
    chart: {
      foreColor: '#E9A628',
    },
    stroke: {
      colors: ['black'],
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '16px',
      fontFamily: 'roboto',
      fontWeight: 400,
      foreColor: '#E9A628',
    },


    responsive: [
      {
        breakpoint: 550,
        options: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
            },
          },
          tooltip: {
            style: {
              fontSize: '12px',
            },
          },
          chart: {
            width: '100%',
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

        useEffect(() => {
    const getData = async () => {
      try {
        const res = await dt();
        setSeries([res.lencpt, res.lencptu, res.lencpte]);
      } catch (err) {
        setSeries([0, 0, 0]);
      }
    };
    getData();
  }, []);

    return (
        <ReactApexChart options={options} series={series} type="donut" />
      );
}








