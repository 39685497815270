import { MapContainer, Marker, Popup, TileLayer,ZoomControl, LayersControl,LayerGroup,useMap } from 'react-leaflet'
import axios from '../axios';
import React, { useState,useRef,useEffect } from 'react'
//import { geosearch } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import Wkt from 'wicket';
import {  myIconCPT, myIconCPTU,myIconCPTE, myIconHVSR, myIconMASW, myIconDH, icon  } from '../custom_icon';
import LeafletRuler from "../LeafletRuler";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import "leaflet-geosearch/dist/geosearch.css";
import {useNavigate} from 'react-router-dom';
import './MapComponent.css'



function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon
      }
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl)
  }, []);
  return null;
}


export const MapComponent = ({ positionDefault = [44.36, 11.34] }) => {

   function viewDataCPText(e){
      let id=e.target.attributes[0].nodeValue
      axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
      const urlData= 'https://www.shapp-api.sofhare.com/outcpt/'+id+'/proves/'
      const requestData=axios.get(urlData)
      Promise.all([requestData]).then(res  => {
         const responseData = res[0].data[0]

         const wkt_geom = responseData.geom.replace('SRID=4326;','')
         const wkt = new Wkt.Wkt();
         wkt.read(wkt_geom)
         const geojson_geom = wkt.toJson()
         var coords = geojson_geom['coordinates']
         var coords2= [coords[1],coords[0]]

         var veryjson=responseData.output_data.replace(/'/g, '"')
         var datjson=JSON.parse(veryjson)
         var parQC=datjson[0].QC;
         var prof=datjson[0].H;
         var parfs=datjson[0].FS;

       navigate('/sHapp_lstdata',{state:{id:id, qc:parQC, fs:parfs, h:prof, coord: coords2}});

       }).catch(errors => {
         // react on errors.
       })
       }

  function viewDataSISext(e){

      let id=e.target.attributes[0].nodeValue
      axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
      const urliddata='https://www.shapp-api.sofhare.com/outsis/'+id+'/proves/'
      const data= axios.get(urliddata)
      Promise.all([data])
      .then(res  => {
          const values=res[0].data[0]
          const wkt_geom = values.geom.replace('SRID=4326;','')
          const wkt = new Wkt.Wkt();
          wkt.read(wkt_geom)
          const geojson_geom = wkt.toJson()
          var coordsis = geojson_geom['coordinates']
          var coords2= [coordsis[1],coordsis[0]]
          var veryjson=values.data.replace(/'/g, '"')
          var datjson=JSON.parse(veryjson)
          var parMV=datjson['value'][0]['ModelV'];
          var parMH=datjson['value'][0]['ModelH'];
          var parVS=values.vsh;

      navigate('/sHapp_lstdata',{state:{id:id, mv:parMV, mh:parMH, vs:parVS, coord: coords2}});

      }).catch(errors => {
      // react on errors.
      })
      }




  const [geopoints, setGeopoints] = useState([]);
  const [check_geog, setCheck] = useState(true);
  const [check_cpt, setCheck_cpt] = useState(true);
  const [check_cptu, setCheck_cptu] = useState(true);
  const [check_cpte, setCheck_cpte] = useState(true);

  const [sispoints, setSispoints] = useState([]);
  const [check_sis, setChecksis] = useState(true);
  const [check_hvsr, setCheck_hvsr] = useState(true);
  const [check_masw, setCheck_masw] = useState(true);
  const [check_dh, setCheck_dh] = useState(true);

  const [pagesis, setpagesis] = useState(0);
  const [pagegeo, setpagegeo] = useState(0);

  const [loadingGeo, setLoadingGeo] = useState(false);
  const [loadingSIS, setLoadingSIS] = useState(false);


  const navigate = useNavigate();

  const mapRef = useRef();
  const addLayers = (e) => {
    if(e.target.name=='prove_geog'){
        setCheck_cpt(e.target.checked);
        setCheck_cptu(e.target.checked);
        setCheck_cpte(e.target.checked);
        setCheck(e.target.checked);
    }
    if(e.target.name=='prove_cpt'){
        setCheck_cpt(e.target.checked);
    }
    if(e.target.name=='prove_cptu'){
        setCheck_cptu(e.target.checked);
    }
    if(e.target.name=='prove_cpte'){
        setCheck_cpte(e.target.checked);
    }
    if(e.target.name=='prove_sis'){
        setCheck_hvsr(e.target.checked);
        setCheck_masw(e.target.checked);
        setCheck_dh(e.target.checked);
        setChecksis(e.target.checked);
    }
    if(e.target.name=='prove_hvsr'){
        setCheck_hvsr(e.target.checked);
    }
    if(e.target.name=='prove_masw'){
        setCheck_masw(e.target.checked);
    }
    if(e.target.name=='prove_dh'){
        setCheck_dh(e.target.checked);
    }

  };

     useEffect(() => {
        renderGEO();
        countDATA();
     },[pagegeo]);


     useEffect(() => {
        renderSIS();
        countDATA();
     },[pagesis]);


     function countDATA(){
        axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
        Promise.all([axios.get(`https://www.shapp-api.sofhare.com/counter/proves/`)])
        .then(res => {
        const page =Math.ceil((res[0].data.find(item => item.type == 'TOTSIS').Tot_geog)/10);
        const pageg =Math.ceil((res[0].data.find(item => item.type == 'TOTCPT').Tot_geog)/10);
        setpagesis(page);
        setpagegeo(pageg);
        })
     }

        function renderGEO() {
            setLoadingGeo(true);
            for (let pgg = 1; pgg < pagegeo + 1; pgg++) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                const geogndata = [
                    `https://www.shapp-api.sofhare.com/datacpt/proves/?page=${pgg}`,
                ];
                const getgeo = geogndata.map((endpoint) => axios.get(endpoint));
                Promise.all(getgeo)
                    .then(res => {
                        const newdata = res[0].data.results
                            .map(item => {
                                const wkt_geom = item.geom.replace('SRID=4326;', '');
                                const wkt = new Wkt.Wkt();
                                wkt.read(wkt_geom);
                                const geojson_geom = wkt.toJson();
                                const coords2 = geojson_geom['coordinates'];
                                return setGeopoints(geopoints => [...geopoints, { coords: coords2, id: item.id, tipo: item.tipo_prova }]);
                            });
                    })
                    .catch(errors => {
                        console.log(errors);
                    })
                    .finally(() => {
                        setLoadingGeo(false);
                    });
                }
        }

        function renderSIS() {
            setLoadingSIS(true);
            for (let pg = 1; pg < pagesis + 1; pg++) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                const sisdata = [
                    `https://www.shapp-api.sofhare.com/datasis/proves/?page=${pg}`,
                ];
                const getsis = sisdata.map((endpoint) => axios.get(endpoint));
                Promise.all(getsis)
                    .then(res => {
                        const newdatasis = res[0].data.results.map(item => {
                            const wkt_geom = item.geom.replace('SRID=4326;', '');
                            const wkt = new Wkt.Wkt();
                            wkt.read(wkt_geom);
                            const geojson_geom = wkt.toJson();
                            const coords2 = geojson_geom['coordinates'];
                            return setSispoints(sispoints => [...sispoints, { coords: coords2, id: item.id, tipo: item.tipo_prova }]);
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching SIS data:', error.response ? error.response.data : error.message || error);
                    })
                    .finally(() => {
                        setLoadingSIS(false);
                    });
            }
        }


 return (
        <div className="general">
            <div id="legendContainer"></div>
            <div className='map1'>
            <a href="/#" id="downloadAnchorElem" style={{ display:"none" }} >a</a>
                <MapContainer  center={positionDefault} zoom={10} zoomControl={false} inneRef={mapRef} style={{ height: '100%', width: '100%',borderRadius: '7px'}} >
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="CartoDB.DarkMatter">
                            <TileLayer
                                url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer unchecked name="Satellite ESRI">
                            <TileLayer
                                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                attribution='Map Tiles © 2022 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS,Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.Overlay  unchecked name="Vs30 Distribuitions" >
                        <TileLayer
                            url='https://repository.sofhare.com/mappe/vs30/{z}/{x}/{y}.png'
                            attribution='sofHare © 2023 Vs30 Distribuitions '
                            opacity={0.5}
                            eventHandlers={{
                              add: (e) => {
                                console.log("Added Layer:", e.target);
                                // Create a legend element with colors
                                const legend = document.createElement('div');

                                legend.style.position = 'absolute';


                                legend.style.zIndex = 3000;
                                legend.style.display = 'flex';
                                const items = [
                                {color:'rgba(48, 18, 59, 0.5)',label:'0'},
                                 {color:'rgba(40, 188, 235, 0.5)'},
                                 {color:'rgba(164, 252, 60, 0.5)',label:'180'},
                                  {color:'rgba(251, 126, 33, 0.5)'},
                                   {color:'rgba(122, 4, 3, 0.5)',label:'360'} ];

                                items.forEach((item,index) => {
                                  const legendItem = document.createElement('div');
                                  legendItem.style.display = 'flex'; // Display label and color box horizontally
                                  legendItem.style.flexDirection = 'column';
                                  legendItem.style.alignItems = 'center'; // Align items vertically in the center
                                  const colorBox = document.createElement('div');
                                  colorBox.style.backgroundColor = item.color;
                                  colorBox.style.width = '30px';
                                  colorBox.style.height = '15px';
                                  //colorBox.style.marginRight = '10px'; // Adjust the margin between color box and label
                                  legendItem.appendChild(colorBox);
                                  const label = document.createElement('div');
                                  label.textContent = item.label;
                                  label.style.alignItems = 'left';
                                  label.style.fontSize = '12px';
                                  label.style.fontWeight = "bold";
                                  legendItem.appendChild(label);
                                  legend.appendChild(legendItem);

                                });
                                // Append the legend to a container element
                                const container = document.getElementById('legendContainer'); // Replace 'legendContainer' with the actual ID of your container element
                                if (container) {
                                  container.appendChild(legend);
                                }
                                e.target.legend = legend;
                              },
                              remove: (e) => {
                                console.log("Removed layer:", e.target);
                                if (e.target && e.target.legend) {
                                const legend = e.target.legend;
                                console.log("Accessed legend:", legend);

                                // Remove the legend element from the container
                                legend.parentNode.removeChild(legend);

                                // Remove the legend property from the target
                                delete e.target.legend;
                              } else {
                                console.log("Legend is not properly stored or accessed.");
                                }
                              }
                           }}
                        />

                        </LayersControl.Overlay>

                       <div >
                            <LayerGroup  >
                              <MarkerClusterGroup showCoverageOnHover={false} preferCanvas={true}>
                               {
                                geopoints.filter((point,i) =>
                                    (check_cpt==true&&point.tipo=='CPT')||(check_cptu==true&&point.tipo=='CPTU')||(check_cpte==true&&point.tipo=='CPTE')
                                ).map(function(point, i){
                                    var myIcon;
                                    if(point.tipo=='CPT'){
                                        myIcon=myIconCPT;
                                    }
                                    else if(point.tipo=='CPTE'){
                                        myIcon=myIconCPTE;
                                        }
                                    else{
                                        myIcon=myIconCPTU;
                                    }
                                    return (<Marker key={i} position={[point.coords[1], point.coords[0]]} icon={myIcon}>
                                       <Popup>
                                           ID : {point.id}  <br></br>
                                           Tipologia: {point.tipo}
                                           <br>
                                           </br>
                                           <button onClick={viewDataCPText} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 16px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Vedi dati</button>

                                       </Popup>
                                     </Marker>);
                                    }
                                )};
                              </MarkerClusterGroup>


                              <MarkerClusterGroup showCoverageOnHover={false} preferCanvas={true}>
                               {
                                sispoints.filter((point,i) =>
                                    (check_hvsr==true&&point.tipo=='HVSR')||(check_masw==true&&point.tipo=='MASW')||(check_dh==true&&point.tipo=='DH')
                                ).map(function(point, i){
                                    var myIcon;
                                    if(point.tipo=='HVSR'){
                                        myIcon=myIconHVSR;
                                    }
                                    else if(point.tipo=='MASW'){
                                        myIcon=myIconMASW;
                                        }
                                    else{
                                        myIcon=myIconDH;
                                    }

                                    return (<Marker key={i} position={[point.coords[1], point.coords[0]]} icon={myIcon}>
                                       <Popup>
                                           ID : {point.id}  <br></br>
                                           Tipologia: {point.tipo}
                                           <br>
                                           </br>
                                           <button onClick={viewDataSISext} point-id={point.id} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',padding: '5px 16px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Vedi dati</button>

                                       </Popup>
                                     </Marker>);
                                    }
                                )};
                              </MarkerClusterGroup>

                            </LayerGroup>
                      </div>
                      </LayersControl>
                    <ZoomControl position='topleft'/>
                    <LeafletRuler />
                   <LeafletgeoSearch />
                </MapContainer>

            </div>
            <div className="layertab">
                  <table>
                      <tbody>
                            <tr>
                              <th>Layer</th>
                            </tr>

                            <tr>
                              <td><input type="checkbox" checked={check_geog} onChange={addLayers}  name="prove_geog" /></td>
                              <td></td>
                              <td>Prove geognostiche</td>

                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cpt} onChange={addLayers}  name="prove_cpt" /></td>
                              <td>Prove CPT</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cpt.png" height="35" alt="image_cpt"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cptu} onChange={addLayers}  name="prove_cptu" /></td>
                              <td>Prove CPTU</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cptu.png" height="35" alt="image_cptu"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_cpte} onChange={addLayers}  name="prove_cpte" /></td>
                              <td>Prove CPTE</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_cpte.png" height="35" alt="image_cpte"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td><input type="checkbox" checked={check_sis} onChange={addLayers}  name="prove_sis" /></td>
                              <td></td>
                              <td>Prove geofisiche</td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_hvsr} onChange={addLayers}  name="prove_hvsr" /></td>
                              <td>Prove HVSR</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_hvsr.png" height="35" alt="image_hvsr"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_masw} onChange={addLayers}  name="prove_masw" /></td>
                              <td>Prove MASW</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_masw.png" height="35" alt="image_masw"/>
                                  </a>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td><input type="checkbox" checked={check_dh} onChange={addLayers}  name="prove_dh" /></td>
                              <td>Prove DH</td>
                              <td><a href="https://it.wikipedia.org/wiki/Prova_penetrometrica_statica" target="_blank" rel="noreferrer">
                                    <img src="/icon_dh.png" height="35" alt="image_dh"/>
                                  </a>
                              </td>
                            </tr>

                      </tbody>
                  </table>
            </div>
           {(loadingGeo || loadingSIS) && (
            <div className="loading-indicator">
                <div className="spinner"></div>
                <p>Loading...</p>
            </div>
        )}
        </div>

  )

}

