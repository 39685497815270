import React, { Component, Fragment, useState, useContext } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import './Home.css';
import Dimage from './immagine_home.png';
import Profile from './img_profilo.png';

function Home() {


    const navigate = useNavigate();
    const location = useLocation();

    return(

        <div>

            <div className="im-i">
                    <div className="title-im">
                       CHI SONO
                    </div>

                    <div className="text-im">
                       <img className="face" src={Profile} />
                       <br/>
                       <br/>
                       Umberto Grechi, classe 1988, geometra e geologo per formazione, esperto in cartografia, topografia e GIS. Sviluppo piattaforme WebGIS e plugin per piattaforme GIS.

                    </div>
                    <br/>
             </div>

            <div className="home-title">
                    <h1> Think Good, Feel GIS! </h1>
             </div>
             <br/>
            <div className="home-container">
                <div className="bk-image">
                    <img style={{ width: "60%", height: "60%" ,}} src={Dimage} />
                 </div>
            </div>
            <br/>






        </div>


    );
}


export default Home;
