import './shapp_p1.css';
import 'leaflet/dist/leaflet.css';
import './footer-bs.css';
import React, { useState } from 'react';
import { MapComponent } from './components/MapComponent';
import {Graph } from './components/piechart';
import {Graphsis } from './components/piechart_sis';
import {DataContext} from './context/DataContext';
import { useNavigate } from 'react-router-dom';
import axios from './axios';
import Wkt from 'wicket';



function Shapp_p1() {
    const [parQC, setparQC] = useState(null)
    const [parfs, setparfs] = useState(null)
    const [prof, setprof] = useState(null)
    const [parMV,setparMV] = useState(null)
    const [parMH,setparMH] = useState(null)
    const [parVS,setparVS] = useState(null)

    const navigate = useNavigate();

     function viewLastGeogn(){

          axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
          const urlData= 'https://www.shapp-api.sofhare.com/outcpt/123456/proves/'
          const requestData=axios.get(urlData)
          Promise.all([requestData]).then(res  => {
            const responseData = res[0].data[0]
            const wkt_geom = responseData.geom.replace('SRID=4326;','')
            const wkt = new Wkt.Wkt();
            wkt.read(wkt_geom)
            const geojson_geom = wkt.toJson()
            var coords = geojson_geom['coordinates']
            var coords2= [coords[1],coords[0]]
            var idft= responseData.id
            var veryjson=responseData.output_data.replace(/'/g, '"')
            var datjson=JSON.parse(veryjson)
            var parQC=datjson[0].QC;
            var prof=datjson[0].H;
            var parfs=datjson[0].FS;

          navigate('/sHapp_lstdata',{state:{id:idft, qc:parQC, fs:parfs, h:prof, coord: coords2}});

          }).catch(errors => {
          // react on errors.
          })
         }

     function viewLastSIS(){

          axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
          const data= axios.get('https://www.shapp-api.sofhare.com/outsis/123456/proves/')
          const vall=Promise.all([data])
          .then(res  => {
             const values=res[0].data[0]
             const wkt_geom = values.geom.replace('SRID=4326;','')
             const wkt = new Wkt.Wkt();
             wkt.read(wkt_geom)
             const geojson_geom = wkt.toJson()
             var coordsis = geojson_geom['coordinates']
             var coordsis2= [coordsis[1],coordsis[0]]
             var idft= values.id
             var veryjson=values.data.replace(/'/g, '"')
             var datjson=JSON.parse(veryjson)
             var parMV=datjson['value'][0]['ModelV'];
             var parMH=datjson['value'][0]['ModelH'];
             var parVS=values.data.VS;
             setparMV(parMV)
             setparMH(parMH)
             setparVS(parVS)

          navigate('/sHapp_lstdata',{state:{id:idft, mv:parMV, mh:parMH, vs:parVS, coord: coordsis2}});

          }).catch(errors => {
             console.error(errors);
          })
         }


    return(
        <DataContext.Provider value={{parQC,setparQC,prof,setprof,parfs,setparfs,parMV,setparMV,parMH,setparMH,parVS,setparVS}}>
        <div>
            <div className="Principal">
                <div className="Title">
                    <h1> sHApp </h1>
                </div>

                <div className="App">
                    <MapComponent />
                </div>

                <div className="piechart1">
                    <Graph />
                </div>

                <div className="piechart2">
                    <Graphsis />
                </div>

                <div className="infotab">
                  <table>
                      <tbody>
                            <tr>
                              <th>Pannello di gestione</th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                              <td>Ultimo caricamento <br></br> prova geotecnica</td>
                              <td>
                              <button onClick={viewLastGeogn} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',width:'100px', height:'25px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Visualizza</button>
                              </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                              <td>Ultimo caricamento <br></br> prova geofisica</td>
                              <td>
                              <button onClick={viewLastSIS}  type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',width:'100px', height:'25px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Visualizza</button>
                              </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                              <td>Richiedi elaborazione</td>
                              <td>
                              <button onClick={() => navigate('/sHapp_insdata')} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',width:'100px', height:'25px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Inserisci dati
                              </button>
                              </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                              <td>Assistenza</td>
                              <td>
                              <button onClick={() => navigate('/Ticket')} type="button" style={{'fontFamily':'roboto','fontSize': '11px',border:'2px solid #12455b',color: 'black',width:'100px', height:'25px',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}>Ticket</button>
                              </td>
                            </tr>
                             <tr>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                            </tr>
                      </tbody>
                  </table>
                </div>
            </div>

        </div>
        </DataContext.Provider>
    );
}


export default Shapp_p1;




