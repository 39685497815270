import './Webgis.css';
import wbimage from './webgis_img.png';
import plimage from './plugin_img.png';


function Webgis() {

    return(

        <div className="Principal-wb">
            <div className="home-title">
                    <h1> Sviluppo WebGIS e Plugin</h1>
            </div>
            <br/>
            <div className="sv-container">
                  <div className="title-sv">
                       <i class="fa fa-map-o fag_custom " aria-hidden="true"></i> Soluzioni WebGIS
                  </div>

                    <div className="text-sv">
                       <br/>
                          - Sviluppo piattaforme WebGIS
                       <br/>
                       <br/>
                          - Sviluppo Tools per piattaform WebGIS
                       <br/>
                       <br/>
                          - Creazione database Geospaziali
                       <br/>
                       <br/>
                       <br/>
                    </div>

                    <div className="title-sv">
                        <i class="fa fa-code fag_custom " aria-hidden="true"></i> Sviluppo Plugin piattaforma GIS
                    </div>
                      <div className="text-sv">
                       <br/>
                          - Automazione processi di elaborazione dati
                       <br/>
                       <br/>
                          - Georeferenzialzione del dato elaborato
                       <br/>
                       <br/>
                          - Riduzione dei tempi di lavoro
                    </div>
                    <br/>

                    <div className="wbgs-container">
                        <div className="bk-image">
                            <img style={{ width: "100%", height: "100%" ,}} src={wbimage} />
                        </div>
                    </div>

                    <div className="pl-container">
                        <div className="bk-image">
                            <img style={{ width: "100%", height: "100%" ,}} src={plimage} />
                        </div>
                    </div>

            </div>
            <br/>

        </div>


    );
}


export default Webgis;
