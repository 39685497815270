import axios from 'axios';
// config

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.status) || 'error')
);


axiosInstance.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;

export default axiosInstance;
