import {PaneQC } from './components/dataview';
import {Panefs } from './components/dataviewfs';
import {PaneVS } from './components/dataviewmodelvs';
import {DataContext} from './context/DataContext';
import React, { useState } from 'react';
import { MapComponent_Page2 } from './components/MapComponent_Page2';
import { useNavigate } from 'react-router-dom';
import './shapp_p2.css';


function Shapp_p2() {

    const [parQC,setparQC] = useState(null)
    const [parfs,setparfs] = useState(null)
    const [prof,setprof] = useState(null)
    const [parMV,setparMV] = useState(null)
    const [parMH,setparMH] = useState(null)
    const [parVS,setparVS] = useState(null)
    const navigate = useNavigate();

    return(
        <DataContext.Provider value={{parQC,setparQC,prof,setprof,parfs,setparfs,parMV,setparMV,parMH,setparMH,parVS,setparVS}}>
        <div className="Container_page2">
            <div className="Principal2">
                <div className="Title">
                    <h1> sHApp </h1>
                </div>

                <div className="App_pg2">
                    <MapComponent_Page2 />
                </div>

                <div className="pane1">
                    <PaneQC />
                </div>

                <div className="pane2">
                    <Panefs />
                </div>

                 <div className="pane3">
                    <PaneVS />
                </div>

                <div className="info">
                    <h2> Qc/fs-Profondità </h2>
                </div>

                <div className="infosis">
                    <h2> Vs-Profondità  </h2>
                </div>

                <div className="bt_return">
                    <button onClick={() => navigate('/sHapp')} type="button" style={{'fontFamily':'roboto','fontSize': '24px',border:'3px solid #12455b',color: 'black',cursor: 'pointer','borderRadius': '12px','backgroundColor': 'white','marginTop': '3px'}}> <i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                </div>

            </div>
        </div>
        </DataContext.Provider>

    );
}


export default Shapp_p2;




