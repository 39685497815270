import { MapContainer, Marker, Popup, TileLayer,ZoomControl, LayersControl,useMap, useMapEvents } from 'react-leaflet'

import React, { useState,useRef,useEffect } from 'react'
//import { geosearch } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import { icon,icon_new_data } from '../custom_icon';
import LeafletRuler from "../LeafletRuler";
import "react-leaflet-markercluster/dist/styles.min.css";
//import style from './MapComponent.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import "leaflet-geosearch/dist/geosearch.css";
import './MapComponent_Page3.css';
import {useNavigate} from 'react-router-dom';

import emailjs from 'emailjs-com';
import { PickerOverlay } from 'filestack-react';
import { toast } from 'react-toastify';
import { LatLngBounds } from "leaflet";



function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon
      }
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl)
  }, []);
  return null;
}





function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    emailjs.sendForm('service_u317szf', 'template_gyjtsht', e.target, 'Xl4cHzs5_rTnbe391')
      .then((result) => {
          toast.success("Prova caricata con successo!", {onClose: () => {window.location.reload();},autoClose: 2000});
            //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
      }, (error) => {
          console.log(error.text);
      });
  }


export const MapComponent_Page3 = ({addMarker}) => {

  const mapRef = useRef();
  const inputRef = useRef(null);
  const [markers, setMarkers] = useState(null);
  const [mapCenter, setMapCenter] = useState([44.36, 11.34]);

  const [isPickerOverlayVisible, setIsPickerOverlayVisible] = useState(false);
  const options = {
    accept: [".zip"],
    allowManualRetry: true,
    fromSources: ["local_file_system","googledrive"],
    minFiles: 1,
    maxFiles: 5,
    onUploadDone(file) {
    document.getElementById('url_data').value = file.filesUploaded[0].url
        }
    }


    const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setMarkers({ lat, lng });
    setMapCenter({ lat, lng });
    const map = mapRef.current;
    if (map) {
    map.setView([lat, lng]);
    }  
    };

    const addMapClickListener = (map) => {
    mapRef.current = map;
    mapRef.current.on("click", handleMapClick);
  };

    const handleAddMarker = () => {
    const inputCoordinates = inputRef.current.value.split(",");
    if (inputCoordinates.length === 2) {
       const [lat, lng] = inputCoordinates.map((coord) => parseFloat(coord));
    if (!isNaN(lat) && !isNaN(lng)) {
            setMarkers({ lat, lng });
            setMapCenter([lat, lng]);
            const map = mapRef.current;
            if (map) {
            map.setView([lat, lng]);
              }
            }
        };}


 return (
        <div className="general_3">
            <div className='map3'>
            <a href="/#" id="downloadAnchorElem" style={{ display:"none" }} >a</a>
                <MapContainer  center={mapCenter} zoom={10} zoomControl={false} whenCreated={addMapClickListener} style={{ height: '100%', width: '100%',borderRadius: '7px'}}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="CartoDB.DarkMatter">
                            <TileLayer
                                url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer unchecked name="Satellite ESRI">
                            <TileLayer
                                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                attribution='Map Tiles © 2022 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS,Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
                            />
                        </LayersControl.BaseLayer>

                      </LayersControl>
                    <ZoomControl position='topleft'/>
                    <LeafletRuler />
                   <LeafletgeoSearch />



                  {markers === null ? null :  (
                    <Marker position={[markers.lat, markers.lng]} icon={icon_new_data}>
                    <Popup>Nuova prova<br></br>
                    Lat,Lng: {markers.lat.toFixed(4)},{markers.lng.toFixed(4)}
                    </Popup>
                    </Marker>
                  )}




                </MapContainer>

             <div className="layertab_newdata">
                <div className="form_data">
                   <form className="contact-form" onSubmit={sendEmail}>
                      <label>Coordinate</label>
                      <br />
                      <input ref={inputRef} type="text" id='coord' name="coordinate" />
                      <button type="button" className="attachment" onClick={handleAddMarker}>
                      <i className="fa fa-crosshairs" aria-hidden="true"></i>
                      </button>
                      <br />

                      <label>Indirizzo</label>
                      <br />
                      <input type="text" name="address" />
                      <br />

                      <label>Intestazione prova</label>
                      <br />
                      <input type="text" name="committente_prove" />
                      <br />

                      <label>Tipo prova</label>
                      <br />
                      <input type="text" name="tipo_prova" />
                      <br />

                      <label>e-mail</label>
                      <br />
                      <input type="text" id='email' name="email" />
                      <br />

                      <label>url dati</label>
                      <br />
                      <input type="text" id="url_data" name="url" placeholder=" " />

                      <button type="button" className="attachment" onClick={() =>
                                    isPickerOverlayVisible
                                    ? setIsPickerOverlayVisible(false)
                                    : setIsPickerOverlayVisible(true)
                                    }>
                                <i className="fa fa-upload" aria-hidden="true"></i>
                      </button>
                            {isPickerOverlayVisible && <PickerOverlay apikey='A7UoAZ540SQK0rvnBnwPgz' pickerOptions={options} />}


                      <br />
                      <label>Note</label>
                      <br />
                      <textarea name="note" />
                      <br />

                      <input type="submit" value="Invia richiesta" />
                   </form>
                  <br />







                </div>
             </div>
            </div>

        </div>

  )

}
