import React, { useState, useEffect,useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from '../axios';
import {DataContext} from '../context/DataContext'

const dt = () => {
  return new Promise((resolve, reject) => {

    axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem('token')}`;
    axios
      .get(`https://www.shapp-api.sofhare.com/counter/proves/`)
      .then((res) => {
        const filtelongeur  = res.data.find(item => item.type == 'TOTSIS');
        const longeur  = filtelongeur.Tot_geog;
        const filterhvsr = res.data.find(item => item.type == 'HVSR');
        const lenhvsr = filterhvsr.Tot_geog;
        const filtermasw = res.data.find(item => item.type == 'MASW');
        const lenmasw = filtermasw.Tot_geog;
        const filterdh = res.data.find(item => item.type == 'DH');
        const lendh = filterdh.Tot_geog;
        resolve({
          lenhvsr,
          lenmasw,
          lendh,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const Graphsis = () => {
    //const {lenhvsr,lenmasw,lendh} = useContext(DataContext)

    //console.log(lenhvsr)
    //const lenhvsr=30
    //const lenmasw=24
    //const lendh=0
    const [series, setSeries] = useState([0, 0, 0]);

      const [options] = useState({
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '18px',
        fontFamily: 'roboto',
        fontWeight: 800,
        colors: ['#CC929292'],
      },
      background: {
        enabled: true,
        foreColor: 'black',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#E9A628',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
    },

    plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                value: {
                  show: true,
                  fontSize: '40px',
                  fontFamily: 'roboto',

                  color: '#E9A628',
                  offsetY: 20,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Totale',
                  color: '#E9A628',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: true,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: true,
      theme: false,
      style: {
        fontSize: '14px',
        fontFamily: 'roboto',
      },
    },
    labels: ['HVSR', 'MASW', 'DH'],

    colors: ['#F36311', '#11B5F3', '#18F902'],
    chart: {
      foreColor: '#E9A628',
    },
    stroke: {
      colors: ['black'],
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '16px',
      fontFamily: 'roboto',
      fontWeight: 400,
      foreColor: '#E9A628',
    },

    responsive: [
      {
        breakpoint: 550,
        options: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
            },
          },
          tooltip: {
            style: {
              fontSize: '12px',
            },
          },
          chart: {
            width: '100%',
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

        useEffect(() => {
    const getData = async () => {
      try {
        const res = await dt();
        setSeries([res.lenhvsr, res.lenmasw, res.lendh]);
        //setSeries([lenhvsr, lenmasw, lendh]);
      } catch (err) {
        setSeries([0, 0, 0]);
      }
    };
    getData();
  }, []);

    return (
        <ReactApexChart options={options} series={series} type="donut" />
      );
}








