import './Geoutilities.css';

function Geoutilities() {


    return(

        <div>

            <div className="im-i2">
                    <div className="title-im">
                       Plugin su piattaforma QGis utile per:
                    </div>

                    <div className="text-im">
                       <br/>
                       <br/>
                          - Elaborazione prove pentrometriche statiche CPT, CPTE e CPTU;
                       <br/>
                       <br/>
                          - Verifica dell'indice di potenziale liquefazione;
                       <br/>
                       <br/>
                          - Valutazione cedimenti post sismici;
                       <br/>
                       <br/>
                          - Realizzazione sezioni stratigrafiche tra prove penetrometriche;
                       <br/>
                       <br/>
                          - Archiviazione prove sismiche e penetrometriche statiche su database;
                       <br/>
                       <br/>
                          - Visualizzazione valori e grafici dei dati archiviati.

                    </div>
                    <br/>
             </div>

            <div className="home-title">
                    <h1> Plugin Geo Utilities</h1>
             </div>
             <br/>
            <div className="geo-container">
                <iframe width="100%" height="60%" src="https://www.youtube.com/embed/oER63EyVB6E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

                ></iframe>
            </div>
            <br/>






        </div>


    );
}


export default Geoutilities;
